import { default as _91_46_46_46page_93klIAaRdBWuMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/[...page].vue?macro=true";
import { default as _91token_93zqDmrsX9YFMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/invite/[token].vue?macro=true";
import { default as login9lkEM6NG8NMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/login.vue?macro=true";
import { default as request_45passwordHV6GYzBKriMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/request-password.vue?macro=true";
import { default as _91token_9381eUQWVPt0Meta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/reset-password/[token].vue?macro=true";
import { default as availabilityKa5wQyIWu4Meta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/availability.vue?macro=true";
import { default as _91uuid_934akL5OpKLFMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/[uuid].vue?macro=true";
import { default as indexzS93VAMONLMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/index.vue?macro=true";
import { default as indexEFx7tEG34CMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/campaign-request/index.vue?macro=true";
import { default as indexPizy9zoXkTMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/index.vue?macro=true";
import { default as personal_45detailsth8Ev7IvPmMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/personal-details.vue?macro=true";
import { default as preferencesrQWj3XvThMMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/preferences.vue?macro=true";
import { default as dashboardzm6bw77NkBMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard.vue?macro=true";
import { default as disclaimer_45and_45terms_45of_45usePPgavzI83oMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/disclaimer-and-terms-of-use.vue?macro=true";
import { default as _91slug_93A3K6KxLY2KMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/[slug].vue?macro=true";
import { default as _91day_93FLAih9wUe5Meta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/of-day/[day].vue?macro=true";
import { default as indexmCNTqPVTabMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/index.vue?macro=true";
import { default as inspirationocIBvV9dcxMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/inspiration.vue?macro=true";
import { default as indexZqnt09fcCmMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/schedule/index.vue?macro=true";
import { default as _91slug_93C5ySkVpIJlMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/[slug].vue?macro=true";
import { default as indexwgF745xC7AMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/index.vue?macro=true";
import { default as subscribep4quslsTaRMeta } from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/subscribe.vue?macro=true";
export default [
  {
    name: "page___de",
    path: "/:page(.*)*",
    meta: _91_46_46_46page_93klIAaRdBWuMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/[...page].vue")
  },
  {
    name: "page___en",
    path: "/:page(.*)*",
    meta: _91_46_46_46page_93klIAaRdBWuMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/[...page].vue")
  },
  {
    name: "page___fr",
    path: "/:page(.*)*",
    meta: _91_46_46_46page_93klIAaRdBWuMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/[...page].vue")
  },
  {
    name: "page___nl",
    path: "/:page(.*)*",
    meta: _91_46_46_46page_93klIAaRdBWuMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/[...page].vue")
  },
  {
    name: "page___pl",
    path: "/:page(.*)*",
    meta: _91_46_46_46page_93klIAaRdBWuMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/[...page].vue")
  },
  {
    name: "auth-invite-token___de",
    path: "/auth/invite/:token()",
    meta: _91token_93zqDmrsX9YFMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/invite/[token].vue")
  },
  {
    name: "auth-invite-token___en",
    path: "/auth/invite/:token()",
    meta: _91token_93zqDmrsX9YFMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/invite/[token].vue")
  },
  {
    name: "auth-invite-token___fr",
    path: "/auth/invite/:token()",
    meta: _91token_93zqDmrsX9YFMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/invite/[token].vue")
  },
  {
    name: "auth-invite-token___nl",
    path: "/auth/invite/:token()",
    meta: _91token_93zqDmrsX9YFMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/invite/[token].vue")
  },
  {
    name: "auth-invite-token___pl",
    path: "/auth/invite/:token()",
    meta: _91token_93zqDmrsX9YFMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/invite/[token].vue")
  },
  {
    name: "auth-login___de",
    path: "/auth/login",
    meta: login9lkEM6NG8NMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___en",
    path: "/auth/login",
    meta: login9lkEM6NG8NMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___fr",
    path: "/auth/login",
    meta: login9lkEM6NG8NMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___nl",
    path: "/auth/login",
    meta: login9lkEM6NG8NMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___pl",
    path: "/auth/login",
    meta: login9lkEM6NG8NMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-request-password___de",
    path: "/auth/request-password",
    meta: request_45passwordHV6GYzBKriMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/request-password.vue")
  },
  {
    name: "auth-request-password___en",
    path: "/auth/request-password",
    meta: request_45passwordHV6GYzBKriMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/request-password.vue")
  },
  {
    name: "auth-request-password___fr",
    path: "/auth/request-password",
    meta: request_45passwordHV6GYzBKriMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/request-password.vue")
  },
  {
    name: "auth-request-password___nl",
    path: "/auth/request-password",
    meta: request_45passwordHV6GYzBKriMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/request-password.vue")
  },
  {
    name: "auth-request-password___pl",
    path: "/auth/request-password",
    meta: request_45passwordHV6GYzBKriMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/request-password.vue")
  },
  {
    name: "auth-reset-password-token___de",
    path: "/auth/reset-password/:token()",
    meta: _91token_9381eUQWVPt0Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password-token___en",
    path: "/auth/reset-password/:token()",
    meta: _91token_9381eUQWVPt0Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password-token___fr",
    path: "/auth/reset-password/:token()",
    meta: _91token_9381eUQWVPt0Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password-token___nl",
    path: "/auth/reset-password/:token()",
    meta: _91token_9381eUQWVPt0Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password-token___pl",
    path: "/auth/reset-password/:token()",
    meta: _91token_9381eUQWVPt0Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/auth/reset-password/[token].vue")
  },
  {
    name: "availability___de",
    path: "/verfugbarkeit",
    meta: availabilityKa5wQyIWu4Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/availability.vue")
  },
  {
    name: "availability___en",
    path: "/availability",
    meta: availabilityKa5wQyIWu4Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/availability.vue")
  },
  {
    name: "availability___fr",
    path: "/disponibilite",
    meta: availabilityKa5wQyIWu4Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/availability.vue")
  },
  {
    name: "availability___nl",
    path: "/beschikbaarheid",
    meta: availabilityKa5wQyIWu4Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/availability.vue")
  },
  {
    name: "availability___pl",
    path: "/dostepnosc",
    meta: availabilityKa5wQyIWu4Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/availability.vue")
  },
  {
    name: "collections-uuid___de",
    path: "/collections/:uuid()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/[uuid].vue")
  },
  {
    name: "collections-uuid___en",
    path: "/collections/:uuid()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/[uuid].vue")
  },
  {
    name: "collections-uuid___fr",
    path: "/collections/:uuid()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/[uuid].vue")
  },
  {
    name: "collections-uuid___nl",
    path: "/collections/:uuid()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/[uuid].vue")
  },
  {
    name: "collections-uuid___pl",
    path: "/collections/:uuid()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/[uuid].vue")
  },
  {
    name: "collections___de",
    path: "/collections",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/index.vue")
  },
  {
    name: "collections___en",
    path: "/collections",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/index.vue")
  },
  {
    name: "collections___fr",
    path: "/collections",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/index.vue")
  },
  {
    name: "collections___nl",
    path: "/collections",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/index.vue")
  },
  {
    name: "collections___pl",
    path: "/collections",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/collections/index.vue")
  },
  {
    name: dashboardzm6bw77NkBMeta?.name,
    path: "/dashboard",
    meta: dashboardzm6bw77NkBMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-campaign-request___de",
    path: "campaign-request",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/campaign-request/index.vue")
  },
  {
    name: "dashboard___de",
    path: "",
    meta: indexPizy9zoXkTMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-personal-details___de",
    path: "personal-details",
    meta: personal_45detailsth8Ev7IvPmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/personal-details.vue")
  },
  {
    name: "dashboard-preferences___de",
    path: "preferences",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/preferences.vue")
  }
]
  },
  {
    name: dashboardzm6bw77NkBMeta?.name,
    path: "/dashboard",
    meta: dashboardzm6bw77NkBMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-campaign-request___en",
    path: "campaign-request",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/campaign-request/index.vue")
  },
  {
    name: "dashboard___en",
    path: "",
    meta: indexPizy9zoXkTMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-personal-details___en",
    path: "personal-details",
    meta: personal_45detailsth8Ev7IvPmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/personal-details.vue")
  },
  {
    name: "dashboard-preferences___en",
    path: "preferences",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/preferences.vue")
  }
]
  },
  {
    name: dashboardzm6bw77NkBMeta?.name,
    path: "/dashboard",
    meta: dashboardzm6bw77NkBMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-campaign-request___fr",
    path: "campaign-request",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/campaign-request/index.vue")
  },
  {
    name: "dashboard___fr",
    path: "",
    meta: indexPizy9zoXkTMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-personal-details___fr",
    path: "personal-details",
    meta: personal_45detailsth8Ev7IvPmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/personal-details.vue")
  },
  {
    name: "dashboard-preferences___fr",
    path: "preferences",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/preferences.vue")
  }
]
  },
  {
    name: dashboardzm6bw77NkBMeta?.name,
    path: "/dashboard",
    meta: dashboardzm6bw77NkBMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-campaign-request___nl",
    path: "campaign-request",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/campaign-request/index.vue")
  },
  {
    name: "dashboard___nl",
    path: "",
    meta: indexPizy9zoXkTMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-personal-details___nl",
    path: "personal-details",
    meta: personal_45detailsth8Ev7IvPmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/personal-details.vue")
  },
  {
    name: "dashboard-preferences___nl",
    path: "preferences",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/preferences.vue")
  }
]
  },
  {
    name: dashboardzm6bw77NkBMeta?.name,
    path: "/dashboard",
    meta: dashboardzm6bw77NkBMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-campaign-request___pl",
    path: "campaign-request",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/campaign-request/index.vue")
  },
  {
    name: "dashboard___pl",
    path: "",
    meta: indexPizy9zoXkTMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-personal-details___pl",
    path: "personal-details",
    meta: personal_45detailsth8Ev7IvPmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/personal-details.vue")
  },
  {
    name: "dashboard-preferences___pl",
    path: "preferences",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/dashboard/preferences.vue")
  }
]
  },
  {
    name: "disclaimer-and-terms-of-use___de",
    path: "/haftungsausschluss-und-nutzungsbedingungen",
    meta: disclaimer_45and_45terms_45of_45usePPgavzI83oMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/disclaimer-and-terms-of-use.vue")
  },
  {
    name: "disclaimer-and-terms-of-use___en",
    path: "/disclaimer-and-terms-of-use",
    meta: disclaimer_45and_45terms_45of_45usePPgavzI83oMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/disclaimer-and-terms-of-use.vue")
  },
  {
    name: "disclaimer-and-terms-of-use___fr",
    path: "/disclaimer",
    meta: disclaimer_45and_45terms_45of_45usePPgavzI83oMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/disclaimer-and-terms-of-use.vue")
  },
  {
    name: "disclaimer-and-terms-of-use___nl",
    path: "/disclaimer-en-gebruiksvoorwaarden",
    meta: disclaimer_45and_45terms_45of_45usePPgavzI83oMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/disclaimer-and-terms-of-use.vue")
  },
  {
    name: "disclaimer-and-terms-of-use___pl",
    path: "/wylaczenie-odpowiedzialnosci-i-warunki-uzytkowania",
    meta: disclaimer_45and_45terms_45of_45usePPgavzI83oMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/disclaimer-and-terms-of-use.vue")
  },
  {
    name: "flowers-slug___de",
    path: "/blumen/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/[slug].vue")
  },
  {
    name: "flowers-slug___en",
    path: "/flowers/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/[slug].vue")
  },
  {
    name: "flowers-slug___fr",
    path: "/fleurs/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/[slug].vue")
  },
  {
    name: "flowers-slug___nl",
    path: "/bloemen/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/[slug].vue")
  },
  {
    name: "flowers-slug___pl",
    path: "/kwiaty/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/[slug].vue")
  },
  {
    name: "flowers-of-day-day___de",
    path: "/flowers/of-day/:day()",
    meta: _91day_93FLAih9wUe5Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/of-day/[day].vue")
  },
  {
    name: "flowers-of-day-day___en",
    path: "/flowers/of-day/:day()",
    meta: _91day_93FLAih9wUe5Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/of-day/[day].vue")
  },
  {
    name: "flowers-of-day-day___fr",
    path: "/flowers/of-day/:day()",
    meta: _91day_93FLAih9wUe5Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/of-day/[day].vue")
  },
  {
    name: "flowers-of-day-day___nl",
    path: "/flowers/of-day/:day()",
    meta: _91day_93FLAih9wUe5Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/of-day/[day].vue")
  },
  {
    name: "flowers-of-day-day___pl",
    path: "/flowers/of-day/:day()",
    meta: _91day_93FLAih9wUe5Meta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/flowers/of-day/[day].vue")
  },
  {
    name: "index___de",
    path: "/",
    meta: indexmCNTqPVTabMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexmCNTqPVTabMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/",
    meta: indexmCNTqPVTabMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/",
    meta: indexmCNTqPVTabMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/",
    meta: indexmCNTqPVTabMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/index.vue")
  },
  {
    name: "inspiration___de",
    path: "/inspiration",
    meta: inspirationocIBvV9dcxMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/inspiration.vue")
  },
  {
    name: "inspiration___en",
    path: "/inspiration",
    meta: inspirationocIBvV9dcxMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/inspiration.vue")
  },
  {
    name: "inspiration___fr",
    path: "/inspiration",
    meta: inspirationocIBvV9dcxMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/inspiration.vue")
  },
  {
    name: "inspiration___nl",
    path: "/inspiratie",
    meta: inspirationocIBvV9dcxMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/inspiration.vue")
  },
  {
    name: "inspiration___pl",
    path: "/inspiracja",
    meta: inspirationocIBvV9dcxMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/inspiration.vue")
  },
  {
    name: "schedule___de",
    path: "/tagesordnung",
    meta: indexZqnt09fcCmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/schedule/index.vue")
  },
  {
    name: "schedule___en",
    path: "/schedule",
    meta: indexZqnt09fcCmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/schedule/index.vue")
  },
  {
    name: "schedule___fr",
    path: "/ordre-du-jour",
    meta: indexZqnt09fcCmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/schedule/index.vue")
  },
  {
    name: "schedule___nl",
    path: "/agenda",
    meta: indexZqnt09fcCmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/schedule/index.vue")
  },
  {
    name: "schedule___pl",
    path: "/kalendarz",
    meta: indexZqnt09fcCmMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/schedule/index.vue")
  },
  {
    name: "stories-slug___de",
    path: "/geschichten/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/[slug].vue")
  },
  {
    name: "stories-slug___en",
    path: "/stories/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/[slug].vue")
  },
  {
    name: "stories-slug___fr",
    path: "/histoires/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/[slug].vue")
  },
  {
    name: "stories-slug___nl",
    path: "/verhalen/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/[slug].vue")
  },
  {
    name: "stories-slug___pl",
    path: "/historie/:slug()",
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/[slug].vue")
  },
  {
    name: "stories___de",
    path: "/geschichten",
    meta: indexwgF745xC7AMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/index.vue")
  },
  {
    name: "stories___en",
    path: "/stories",
    meta: indexwgF745xC7AMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/index.vue")
  },
  {
    name: "stories___fr",
    path: "/histoires",
    meta: indexwgF745xC7AMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/index.vue")
  },
  {
    name: "stories___nl",
    path: "/verhalen",
    meta: indexwgF745xC7AMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/index.vue")
  },
  {
    name: "stories___pl",
    path: "/historie",
    meta: indexwgF745xC7AMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/stories/index.vue")
  },
  {
    name: "subscribe___de",
    path: "/abonnieren-sie",
    meta: subscribep4quslsTaRMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/subscribe.vue")
  },
  {
    name: "subscribe___en",
    path: "/subscribe",
    meta: subscribep4quslsTaRMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/subscribe.vue")
  },
  {
    name: "subscribe___fr",
    path: "/abonnez-vous",
    meta: subscribep4quslsTaRMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/subscribe.vue")
  },
  {
    name: "subscribe___nl",
    path: "/word-abonnee",
    meta: subscribep4quslsTaRMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/subscribe.vue")
  },
  {
    name: "subscribe___pl",
    path: "/subskrybuj",
    meta: subscribep4quslsTaRMeta || {},
    component: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/pages/subscribe.vue")
  }
]