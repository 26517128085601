import validate from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_nuxt@3.13.2_rollup@4.22.0_tailwindcss@3.4.12_vite@5.4.6_vue@3.5.6/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_nuxt@3.13.2_react-dom@18.3.1_react@18.3.1_rollup@4.22.0_tai_4h4d6nzcrs3blvukmoptdziy5a/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  auth: () => import("/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.2_next-auth@4.21.1_rollup@4.22.0/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}