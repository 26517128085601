import revive_payload_client_NlBwsg89A9 from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gjLfFLjAR1 from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MW4NIX8GXi from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_334KDI2Twg from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.22.0_vite@5.4.6_vue@3.5.6/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_xAGyR5P8xA from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hSZAj5DpWE from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nmpVPdkotu from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_QaxZXO5c3R from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_0iM1Y4jUnC from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.22.0_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Ywkieu1aUu from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.22.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_7T7NqGFde4 from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.22.0_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_8nxexhK8XL from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.22.0_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_uj52Ak66ty from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_react-dom@18.3.1_react@18.3.1_rollup@4.22.0_typescript@5.6.2_vue@3.5.6/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_0ARIkZhuYR from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.2_next-auth@4.21.1_rollup@4.22.0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import errors_PtiAi8MSU1 from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_nuxt@3.13.2_rollup@4.22.0_tailwindcss@3.4.12_vite@5.4.6_vue@3.5.6/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_F2H4uF69Bv from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_nuxt@3.13.2_rollup@4.22.0_tailwindcss@3.4.12_vite@5.4.6_vue@3.5.6/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_VRP8c8RqJN from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_nuxt@3.13.2_react-dom@18.3.1_react@18.3.1_rollup@4.22.0_tai_4h4d6nzcrs3blvukmoptdziy5a/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_TJpx4a44rF from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_nuxt@3.13.2_react-dom@18.3.1_react@18.3.1_rollup@4.22.0_tai_4h4d6nzcrs3blvukmoptdziy5a/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_5KNt3QPGKz from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_next@13.5.7_nuxt@3.13.2_react-dom@18.3.1_react@18.3.1__trztmoi77tsar4lqtifnbrcimy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_J7BL8nn7Gt from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.4.1_next@13.5.7_nuxt@3.13.2_react-dom@18.3.1_react@18.3.1__trztmoi77tsar4lqtifnbrcimy/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import apollo_1ILFkzpYSr from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/apollo.ts";
import dynamic_vw_client_kS7b5RjaKG from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/dynamic-vw.client.ts";
import gtm_client_OzmBuHLRIb from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/gtm.client.ts";
import i18n_VfGcjrvSkj from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/i18n.ts";
import notifications_Wskzy5ZO71 from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/notifications.ts";
import session_qoMCkkKHp8 from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/session.ts";
import version_client_0D8DwfTb7v from "/data/www/365dagenbloemen.nl/365dagenbloemen/nuxt/plugins/version.client.ts";
export default [
  revive_payload_client_NlBwsg89A9,
  unhead_gjLfFLjAR1,
  router_MW4NIX8GXi,
  _0_siteConfig_334KDI2Twg,
  payload_client_xAGyR5P8xA,
  navigation_repaint_client_hSZAj5DpWE,
  check_outdated_build_client_nmpVPdkotu,
  chunk_reload_client_QaxZXO5c3R,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0iM1Y4jUnC,
  plugin_Ywkieu1aUu,
  switch_locale_path_ssr_7T7NqGFde4,
  i18n_8nxexhK8XL,
  formkitPlugin_pZqjah0RUG,
  plugin_uj52Ak66ty,
  plugin_0ARIkZhuYR,
  errors_PtiAi8MSU1,
  i18n_F2H4uF69Bv,
  apollo_VRP8c8RqJN,
  sofie_required_version_TJpx4a44rF,
  apollo_5KNt3QPGKz,
  auth_J7BL8nn7Gt,
  apollo_1ILFkzpYSr,
  dynamic_vw_client_kS7b5RjaKG,
  gtm_client_OzmBuHLRIb,
  i18n_VfGcjrvSkj,
  notifications_Wskzy5ZO71,
  session_qoMCkkKHp8,
  version_client_0D8DwfTb7v
]